import { useEventEmitterContext } from 'context/EventEmitterContext'
import React, {
  MouseEvent,
  cloneElement,
  useEffect,
  useMemo,
  useState
} from 'react'

export const CLOSE_MODAL = 'close_modal'

function ModalTrigger({
  trigger,
  children,
  closeOnEvent,
  openOnLoad = false,
  onOpenClose
}: {
  trigger: CallbackWithParam<
    CallbackWithParam<MouseEvent<HTMLElement>>,
    React.ReactNode
  >
  children: React.ReactNode
  closeOnEvent?: string
  openOnLoad?: boolean
  onOpenClose?: CallbackWithParam<boolean>
}) {
  const [showModal, setShowModal] = useState(openOnLoad)
  const el = useMemo(
    () =>
      trigger((e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()

        setShowModal(true)
      }),
    [trigger]
  )
  const modal = useMemo(
    () =>
      // @ts-ignore
      cloneElement(children, {
        onClose: () => setShowModal(false)
      }),
    [children]
  )

  useEffect(() => {
    onOpenClose?.(showModal)
  }, [onOpenClose, showModal])

  const { emitter } = useEventEmitterContext()
  useEffect(() => {
    // emitter doesn't exist in SSR
    if (typeof window === 'undefined' || !emitter) {
      return
    }
    function hideModal() {
      setShowModal(false)
    }

    emitter.on(CLOSE_MODAL, hideModal)
    if (closeOnEvent) {
      emitter.on(closeOnEvent, hideModal)
    }

    return () => {
      emitter.off(CLOSE_MODAL, hideModal)

      if (closeOnEvent) {
        emitter.off(closeOnEvent, hideModal)
      }
    }
  }, [closeOnEvent, emitter])

  return (
    <React.Fragment>
      {el}
      {showModal && modal}
    </React.Fragment>
  )
}

export default ModalTrigger
