import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import useLocalStorage from 'hooks/useLocalStorage'
import { useCallback, useMemo } from 'react'
import { NoSsr } from 'components/ConditionalSsr/ConditionalSsr'
import TrackableClick from 'components/common/TrackableClick'
import { twMerge } from 'tailwind-merge'

function MFDAnnouncementBannerComponent({ className }: { className?: string }) {
  const [dismissCollectivesAnnouncement, setDismissCollectivesAnnouncement] =
    useLocalStorage<Maybe<boolean>>('dismissCollectivesAnnouncement:mfd') // TODO: if you change content, also update the context here so everyone sees the latest banner

  const dismiss = useCallback(
    () => setDismissCollectivesAnnouncement(true),
    [setDismissCollectivesAnnouncement]
  )

  const content = useMemo(
    () => (
      <>
        <TrackableClick event="CollectiveBannerClick">
          <span className="text-gray-800">
            <Link href="https://www.myfirstdao.xyz/ ">
              <span className="banner-gradient-text">
                {`Introducing My First DAO, an innovative Learn and Earn program
                  for DAOs. `}
              </span>
              <span className="text-blue underline font-light">
                Get your NFT here!
              </span>
            </Link>
          </span>
        </TrackableClick>
        <button
          className="absolute bottom-3 right-8 text-sm"
          type="button"
          onClick={dismiss}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </>
    ),
    [dismiss]
  )

  if (dismissCollectivesAnnouncement) {
    return null
  }

  return className ? (
    <div className={twMerge('relative px-0 py-3 text-center', className)}>
      {content}
    </div>
  ) : (
    <div className="banner-gradient-box">{content}</div>
  )
}

function MFDAnnouncementBanner({
  bannerStyle = 'default'
}: { bannerStyle?: 'grey' | 'default' } = {}) {
  return (
    <NoSsr>
      <MFDAnnouncementBannerComponent
        className={
          bannerStyle === 'grey'
            ? 'bg-white border-b border-gray-300'
            : undefined
        }
      />
    </NoSsr>
  )
}

export default MFDAnnouncementBanner
