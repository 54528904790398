import Footer from './Footer'
import EffectiveNav from './Nav/EffectiveNav'
import MarketingNav from './Nav/MarketingNav'

const GenericLayout: React.FC<{
  bgColor?: string
  fullWidth?: boolean
  effectiveNav?: boolean
  children?: React.ReactNode
}> = ({ children, bgColor, fullWidth, effectiveNav }) => {
  return (
    <div className={bgColor || 'bg-gray-100'}>
      {effectiveNav ? <EffectiveNav /> : <MarketingNav />}

      <div
        className={fullWidth ? 'max-w-full my-10' : 'my-10 mx-auto max-w-7xl'}
      >
        <div
          className={
            !fullWidth ? 'px-4 md:px-8 flex flex-col' : 'flex flex-col '
          }
        >
          {children}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default GenericLayout
