import React from 'react'
import { twMerge } from 'tailwind-merge'

export const CenterContainer: React.FC<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        'flex flex-col justify-center items-center relative gap-4',
        className
      )}
    >
      {children}
    </div>
  )
}
