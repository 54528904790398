import { useEffect, useState, useCallback, useMemo } from 'react'

export const isClient = !!(typeof window !== 'undefined' && window.localStorage)

function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T, CallbackWithParam<T>] {
  const effectiveValue = useMemo(() => {
    if (isClient) {
      const item = window.localStorage?.getItem(key)
      return item ? JSON.parse(item) : initialValue
    }

    return initialValue
  }, [initialValue, key])

  const [storedValue, setStoredValue] = useState<T>(effectiveValue!)

  // load from stroage only after rendered on client side, as to
  // not coflict with SSR renders
  useEffect(() => {
    try {
      const item = window.localStorage?.getItem(key)
      const parsedValue = item ? JSON.parse(item) : initialValue

      if (storedValue != parsedValue) {
        setStoredValue(parsedValue)
      }
    } catch (error) {
      console.log(error)
      setStoredValue(initialValue!)
    }
    // eslint-disable-next-line
  }, [])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        setStoredValue(value)
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(value))
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error)
      }
    },
    [key]
  )

  return [storedValue, setValue]
}

export default useLocalStorage
