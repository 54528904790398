import Link from 'next/link'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/pro-duotone-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useFeaturedEventsQuery } from 'graphql/generated'
import { useMemo } from 'react'
import { random } from 'lodash'
import useLocalStorage from 'hooks/useLocalStorage'

const gradients = [
  ['#DD5E89', '#F7BB97'],
  ['#00d2ff', '#3a7bd5'],
  ['#EC6EAD', '#3494E6'],
  ['#43cea2', '#185a9d'],
  ['#B993D6', '#8CA6DB']
]

function FeaturedEventBanner() {
  const { data } = useFeaturedEventsQuery({
    variables: {
      first: 1
    }
  })
  const feature = data?.featuredEvents?.[0]
  const gradient = useMemo(() => gradients[random(0, gradients.length - 1)], [])
  const [dismissFeaturedEvent, setDismissFeaturedEvent] =
    useLocalStorage<boolean>('dismissFeaturedEvent')
  const dismiss = () => setDismissFeaturedEvent(true)

  if (!feature || dismissFeaturedEvent) {
    return null
  }

  return (
    <div
      style={{
        background: `linear-gradient(45deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`
      }}
    >
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-white opacity-75">
              <FontAwesomeIcon
                icon={faBullhorn}
                className="h-6 w-6 text-gray-900"
              />
            </span>

            <p className="ml-3 font-light text-white truncate">
              <span>
                Tune in to <span className="font-medium">{feature.title}</span>
                <br />
                {'On '}
                {moment(feature.startTime)
                  .tz(moment.tz.guess())
                  .format('llll z')}
              </span>
            </p>
          </div>

          <div className="order-3 mt-2 shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto ml-2">
            <Link
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 opacity-75"
              href={`/events/${feature.id}`}
            >
              RSVP
            </Link>
          </div>

          <div className="order-2 shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              onClick={dismiss}
              className="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <FontAwesomeIcon icon={faTimes} className="h-6 w-6 text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedEventBanner
